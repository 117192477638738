<template>
  <div id="gangting-passagewayList" class="table-container">
    <el-table :data="tableData" style="width: 100%" v-loading="loading">
      <el-table-column prop="channelName" label="出入口名称" align="center">
      </el-table-column>
      <el-table-column prop="channelType" label="设备类型" align="center">
      </el-table-column>
      <el-table-column prop="soundVolume" label="播报音量" align="center">
        <template #default="scope">
          <p class="soundVolume">
            <span>{{ scope.row.soundVolume }}</span>
            <i
              class="iconfont icon-bianji"
              title="编辑"
              @click="soundVolumeChange(scope.row)"
            ></i>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-checkbox
            v-model="scope.row.isCheck"
            @change="checkedChange(scope.row)"
          ></el-checkbox>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        v-model:current-page="page"
        layout="prev, pager, next"
        background
        :total="total"
        :page-size="pageSize"
        @current-change="getList"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      page: 1,
      pageSize: 5,
      total: 0,
    };
  },
  computed: {
    passagewayChecked() {
      return this.$store.state.GangTing.passagewayChecked;
    },
    parkingLotId() {
      return this.$store.state.GangTing.parkingLotId;
    },
  },
  watch: {
    parkingLotId: {
      handler(state) {
        if (state) {
          this.getList();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async soundVolumeChange(item) {
      this.$prompt("", "修改播报音量", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^((?!0)\d{1,2}|100)$/,
        inputErrorMessage: "请输入0-100的整数",
        inputPlaceholder: "请输入您需要修改的值",
      }).then(async ({ value }) => {
        let res = await this.$http.post("/channel/edit", {
          channelId: item.channelId,
          soundVolume: value,
        });
        if (res.code === 0) {
          this.$message.success("编辑成功!");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    async getList(page = this.page) {
      try {
        this.loading = true;
        let res = await this.$http.post("/channel/list", {
          pageNum: page,
          pageSize: this.pageSize,
          parkingLotId: this.parkingLotId,
        });
        if (res.code === 0) {
          const checkList = [];
          let passagewayChecked = localStorage.getItem("passagewayChecked");
          // 判断是否不满四个视频时需要默认添加剩余的
          let isDefault = true;
          if (passagewayChecked) {
            passagewayChecked =
              JSON.parse(passagewayChecked)[this.parkingLotId] ?? [];
            if (passagewayChecked.length) {
              isDefault =
                passagewayChecked[0].parkingLotId !==
                res.data.list[0].parkingLotId;
            }
          } else {
            passagewayChecked = this.passagewayChecked;
          }

          // 加载缓存过的出入口
          if (
            passagewayChecked.length &&
            passagewayChecked[0]?.parkingLotId ===
              res.data.list[0]?.parkingLotId
          ) {
            for (let item of passagewayChecked) {
              let flag = true;
              checkList.push(item.channelId);
              for (let items of this.passagewayChecked) {
                if (item.channelId === items.channelId) {
                  flag = false;
                  break;
                }
              }
              if (flag) {
                item.isCheck = true;
                this.checkedChange(item);
              }
            }
          }
          for (let item of res.data.list) {
            item.info = {};
            item.isCheck = checkList.includes(item.channelId);
          }
          if (!checkList.length) {
            for (const item of res.data.list) {
              if (this.passagewayChecked.length < 4 && isDefault) {
                item.isCheck = true;
                this.checkedChange(item);
              }
            }
          }

          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        console.log(err);
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
    checkedChange(row) {
      if (row?.isCheck) {
        if (this.passagewayChecked.length >= 4) {
          this.$message.warning("最多选中四个！");
          row.isCheck = !row.isCheck;
        } else {
          this.set_passagewayChecked([...this.passagewayChecked, row]);
        }
      } else {
        let list = [...this.passagewayChecked];
        for (let i = 0, len = list.length; i < len; i++) {
          if (list[i].channelId === row.channelId) {
            list.splice(i, 1);
            break;
          }
        }
        if (list.length) {
          this.set_passagewayChecked(list);
        }
      }
    },
    set_passagewayChecked(value) {
      let passagewayChecked = localStorage.getItem("passagewayChecked");
      if (passagewayChecked) {
        passagewayChecked = JSON.parse(passagewayChecked);
        Object.assign(passagewayChecked, {
          [this.parkingLotId]: value,
        });
      } else {
        passagewayChecked = {
          [this.parkingLotId]: value,
        };
      }
      this.$store.commit("GangTing/SET_PASSAGEWAYCHECKED", value);
      localStorage.setItem(
        "passagewayChecked",
        JSON.stringify(passagewayChecked)
      );
    },
  },
};
</script>

<style lang="scss">
#gangting-passagewayList {
  &.table-container {
    padding: 0 !important;
  }
  .soundVolume {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-bianji {
      color: $main-color;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
</style>
